body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Définir un arrière-plan */
  background: url('../../../public/test6.svg')repeat center center;
  background-attachment:local;
  background-size: cover;
  /* Ajuste la taille de l'image pour couvrir tout l'écran */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.not-found-container {
    text-align: center;
    padding: 50px;
    height: 100vh;
  }
  
  .not-found-container h1 {
    font-size: 3em;
    color: #333;
  }
  
  .not-found-container p {
    font-size: 1.2em;
    color: #555;
  }
  
  .not-found-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found-container a:hover {
    text-decoration: underline;
  }
  