.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 9%;
  margin-bottom: 7%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  flex: 1;
  margin-right: 20px;
}

.contact-info h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-info p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
}

.contact-info ul {
  list-style: none;
  padding: 0;
}

.contact-info li {
  margin-bottom: 15px;
}

.contact-info a {
  text-decoration: none;
  color: #0077b5;
  font-size: 1.2rem;
}

.contact-info a:hover {
  color: #005f8b;
}

.contact-info i {
  margin-right: 10px;
}

.contact-form {
  flex: 1;
  margin-left: 20px;
}

.contact-form h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #fff;
}

.contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0077b5;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #005f8b;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-info,
  .contact-form {
    margin: 0;
    margin-bottom: 20px;
  }
}

.no-resize {
  resize: none;
  /* Empêche le redimensionnement */
}



@media screen and (max-width: 855px) {

  .contact-container {
    margin-top: 10%;
    margin-bottom: 15%;
  }

}

@media screen and (max-width: 500px) {

  .contact-container {
    margin-top: 20%;

  }

}