.lesson-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
    margin-bottom: 7%;

  }
  
  .lesson-header {
    background: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      );
    background-size: cover;
    color: white;
    padding: 80px 20px;
    text-align: center;
    position: relative;
  }
  
  .header-overlay {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .lesson-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .lesson-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1px;
  }
  
  .header-button {
    margin-top: 10px;
    background-color: #ff5722;
    color: white;
  }
  
  .lesson-about {
    background: white;
    padding: 50px 20px;
  }
  
  .section-title {
    font-size: 2rem;
    color: #333333;
    margin-bottom: 30px;
  }
  
  .lesson-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .lesson-card {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .card-title {
    color: #ff5722;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .lesson-pricing {
    background: #fafafa;
    padding: 50px 20px;
  }
  
  .pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .pricing-card {
    flex: 1;
    max-width: 300px;
    padding: 20px;
    background: #ffffff;
    border: 2px solid #ff5722;
    border-radius: 10px;
  }
  
  .lesson-contact {
    background: white;
    padding: 50px 20px;
  }
  
  .contact-button {
    background-color: #4caf50;
    margin-top: 20px;
    color: white;
  }
  
  /* Media Queries */
  @media screen and (max-width: 768px) {

    .lesson-container {
      margin-bottom: 15%;
  
    }

    .lesson-header {
      padding: 50px 20px;
    }
  
    .lesson-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  }
  