/* FONT STYLE */

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Spicy+Rice&display=swap');

/* Container styles */
.container {
  position: relative;
  width: 100%;
}

/* Navbar styles */
.navbar {
  font-family: 'Fira Code', sans-serif;
  letter-spacing: 1.5px;

  position: fixed; /* Makes the navbar fixed at the top */
  top: 0;
  width: 100%;
  height: 80px; /* Adjust height as needed */
  background-color: #ecddbb; /* Warm beige background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1000; /* Ensures it stays above other content */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Transparent Navbar */
.navbar-opaque {
  background-color: transparent;
  color: white;
  box-shadow: none;
}

/* Colored Navbar */
.navbar-colored {
  background-color: #ecddbb; /* White background when scrolled */
  color: #333333; /* Dark text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Navbar logo */
.navbar-logo img {
  height: 50px; /* Adjust the size of the logo */
  border-radius: 20%; /* Makes the border circular (optional, depending on logo shape) */
  padding: 5px; /* Adds spacing between the image and border */
  transition: border-color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-list {
  display: flex;
  list-style: none;
  margin-right: 1cm;
}

.nav-item {
  margin: 0 15px; /* Adjust spacing between items */
}

.nav-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #5C2111; /* Forest green for the links */
  padding: 5px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: #FFFFFF; /* White text on hover/active */
  background-color: #5C2111; /* Terracotta for hover/active background */
  border-radius: 5px; /* Rounded corners for hover effect */
}

.nav-toggle {
  display: none;
}
/* Responsive styles */
@media screen and (max-width: 855px) {
  .nav {
    display: none;
    flex-direction: column;
    background-color: #ecddbb; /* Example background for dropdown menu */
    position: absolute;
    top: 10px; /* Adjust to fit below the header */
    right: 10px;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    z-index: 50;
    border: 10cm;
    border-color: #050505;
  }

  .navbar-colored {
    background-color: #ecddbb00;
    box-shadow: none;
  }

  .nav-visible {
    display: flex;
    flex-direction: column;
    background-color: #ecddbb; /* Example background for dropdown menu */
    position: absolute;
    top: 10px; /* Adjust to fit below the header */
    right: 10px;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    z-index: 50;
    border: 10cm;
    border-color: #050505;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    margin: 15px 0;
  }

  .nav-toggle {
    display: block; /* Show toggle button */
    position: fixed;
    top: 10px; /* Adjust distance from the top of the screen */
    right: 10px; /* Align it to the right edge of the screen */
    justify-content: flex-end; /* Align to the right */
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: #ecddbb;
    color: #000000;
    border-radius: 20%;
    z-index: 2000; /* Ensure it is above everything */
  }
}
