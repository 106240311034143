
.projects-container {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 7%;
  margin-bottom: 7%;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-list {
  list-style-type: none;
  padding: 0;
}

.project-item {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-title {
  font-size: 1.5rem;
  color: #333333;
  margin: 0;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  color: #007BFF;
  transition: transform 0.3s ease;
}

.toggle-button:hover {
  transform: rotate(180deg);
}

.project-themes-year {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.project-themes {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px 0;
  gap: 10px; /* Espace entre les éléments */
}

.project-themes h4 {
  background-color: #007BFF;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block; /* Évite que les éléments se regroupent */
}


.project-themes-container {
  max-width: 100%;
  overflow-x: auto; /* Ajoute un défilement horizontal pour les conteneurs de technologies */
  padding-bottom: 10px; /* Ajoute un espace pour le défilement */
}


.project-year {
  flex-shrink: 0;
  margin-left: 10%;
  text-align: right;
  align-self: center; /* Aligne verticalement au centre */
}

.theme {
  color: #7e7e7e;
}

.theme-image {
  width: 70px;
  height: 50px;
  border: 2px solid #C79323;
  object-fit: cover;
}

.project-abstract {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
  font-size: 1rem;
  color: #666666;
  animation: fadeIn 0.5s ease;
}

.divider {
  margin: 10px 0;
  border: none;
  border-top: 2px solid #007BFF;
}

.info-text {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (max-width: 855px) {

  .projects-container {
    margin-top: 10%;
    margin-bottom: 15%;
  }

}

@media screen and (max-width: 500px) {

  .projects-container {
    margin-top: 20%;
    margin-bottom: 20%;
  }

}