.footer {
  background-color: #ecddbb;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center; /* Centre tout le contenu horizontalement */
  bottom: 0; /* Positions the footer at the bottom */
  left: 0; /* Aligns the footer to the left edge */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1%;
  /* max-width: 1200px; /* Limite la largeur maximale pour mieux gérer les grands écrans */
}

.footer-image {
  display: flex;
  justify-content: center; /* Centre l'image horizontalement */
  align-items: center; /* Centre l'image verticalement */
  flex: 1; /* Prend tout l'espace disponible pour centrer */
}

.footer-image img {
  width: 50px;
  height: auto;
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-links a {
  margin: 0 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.footer-links img {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.footer-links img:hover {
  transform: scale(1.2);
}
