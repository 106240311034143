/* General Container */
.activities-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 6%;
  margin-bottom: 7%;

}

/* Grid Layout */
.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Card Design */
.activity-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 15px;
  color: white;
}

/* Dynamic background colors for each activity */
.activity-card:nth-child(1) {
  background-color: #6A7BA2; /* Modern blue-gray */
}

.activity-card:nth-child(2) {
  background-color: #E07A5F; /* Warm terracotta */
}

.activity-card:nth-child(3) {
  background-color: #81B29A; /* Soft green */
}

.activity-card:nth-child(4) {
  background-color: #3D405B; /* Deep gray-blue */
}

.activity-card:nth-child(5) {
  background-color: #F2CC8F; /* Warm beige-yellow */
  color:#264653;
}

.activity-card:nth-child(6) {
  background-color: #264653; /* Dark teal */
}

.activity-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.activity-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.activity-title {
  font-size: 1.5rem;
  margin: 15px 0;
  font-family: 'Fira Code', sans-serif;
}

.activity-description {
  font-size: 1rem;
  line-height: 1.5;
}

.activity-secondary-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 0 0 10px 10px;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {

  .activities-container {
    margin-top: 10%;
    margin-bottom: 15%;

  }

  .activities-title {
    font-size: 2rem;
  }

  .activity-title {
    font-size: 1.2rem;
  }

  .activity-description {
    font-size: 0.9rem;
  }
}
