@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Spicy+Rice&display=swap');

.avatar-image {
  background-color: #BE9F71;
  border-radius: 50%;

  width: 450px;
  height: 450px;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  /* Ensure it stays above the background image */
}

.home-container {
  margin-bottom: 7%;
}

.image-container {
  width: 100%;
  /* Full-width container */
  height: auto;
  /* Let the height adjust naturally */
  overflow: hidden;
  /* Ensure no unwanted overflow */
  position: relative;
  /* Ensures positioning for responsive adjustments */
}

.responsive-image {
  width: 100%;
  /* Makes the image adapt to the width of the screen */
  height: auto;
  /* Maintains the aspect ratio */
  object-fit: cover;
  /* Ensures the image covers the container while preserving the aspect ratio */

  /*position: relative; /* Allows proper positioning */
}

.welcome-text {
  font-family: "Spicy Rice";
  font-size: 60px;
  margin-top: 0%;
  margin-bottom: 0%;
}

.introduction-text {
  font-family: 'Fira Code';
  margin-top: 0%;
  }

.hello-text {
  font-family: "Fira Code";
  margin-bottom: 0%;
}

/* text for switch */

/* Container for text sections */
.text-switcher {
  margin: 40px auto;
  text-align: center;
  max-width: 800px;
  padding: 20px;
  border-radius: 40px;
}

.text-switcher.about {
  background-color: #81855D;
}

.text-switcher.portfolio {
  background-color: #B56A3D;
}

.text-content h1 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: 'Fira Code';
  text-decoration: underline;

}

.text-content p {
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 1.8;
  font-family: 'Fira Code';
}

/* Dots for navigation */
.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 8px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #333;
  /* Active dot color */
}

.dot:hover {
  background-color: #555;
}

@media screen and (max-width: 1100px) {
  .avatar-image {
    background-color: #BE9F71;
    border-radius: 50%;

    width: 200px;
    height: 200px;
  }

  .home-container {
    margin-bottom: 15%;
  }


}

@media screen and (max-width: 768px) {

  .home-container {
    margin-bottom: 15%;
  }

  
  .welcome-text {
    font-size: 45px;
  }

  .introduction-text {
    font-size: 20px;
  }

  .hello-text {
    font-size: 16px;
  }

  .avatar-image {
    width: 120px;
    height: 120px;
  }

  .text-content h1 {
    font-size: 1.8rem;
  }

  .text-content p {
    font-size: 1rem;
  }
}

/* Responsive Styles for Mobile Phones */
@media screen and (max-width: 480px) {

  .home-container {
    margin-bottom: 15%;
  }

  .welcome-text {
    font-size: 36px;
  }

  .introduction-text {
    font-size: 18px;
  }

  .hello-text {
    font-size: 14px;
  }

  .avatar-image {
    width: 100px;
    height: 100px;
    z-index: 2;
  }

  .text-switcher {
    padding: 15px;
  }

  .text-content h1 {
    font-size: 1.6rem;
  }

  .text-content p {
    font-size: 0.9rem;
  }

  .color-bar {
    height: 20px; /* Adjust bar height for smaller screens */
    background-color: #E4D9BA;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1; /* Stays behind the avatar */
  }

  .responsive-image{
    padding-top: 40%;
    background-color: #E4D9BA;
  }

  .introduction-text {
    font-family: 'Fira Code';
    color: #333;
    background-color: #ffffffad;
  }

}