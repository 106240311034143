.skills-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-top: 6%;
  margin-bottom: 7%;
}

.skills-card {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

  .languages {
    background-color: #D5E8D4;
  }

  .certifications {
    background-color: #E6D0DE;
  }

.skills-card:hover {
  transform: translateY(-10px);
}

.soft-skills {
  background-color: #e6f7ff;
}

.hard-skills {
  background-color: #fffbe6;
}

.skills-card h2 {
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin-bottom: 10px;
}

.skills-card ul {
  list-style: none;
  padding: 0;
}

.skills-card ul li {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.skills-card ul li i {
  margin-right: 8px;
}

.blender-renders {
  text-align: center;
}

.render-images {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.render-image {
  max-width: 45%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 855px) {

  .skills-container {
    margin-top: 10%;
    margin-bottom: 15%;
  }

}